<template>
	<div class="audio-analysis">
		<h4 class="mt-2">Audio Analysis</h4>
		<svg
			xwidth="1000"
			xheight="200"
			viewBox="0 0 1000 200"
		>
			<rect
				v-for="(beat, i) in trackAudioAnalysis.segments"
				:key="i"
				:x="secondsToWidth(beat.start)"
				:y="200-loudnessToHeight(beat.loudness_max)"
				:width="secondsToWidth(beat.duration)"
				:height="loudnessToHeight(beat.loudness_max)"
			></rect>
			<polygon :points="points"></polygon>
		</svg>
	</div>
</template>

<script>
export default {
	props: {
		trackAudioAnalysis: Object,
	},
	computed: {
		width: function () {
			return this.trackAudioAnalysis.track.duration;
		},
		points: function () {
			const points = [[0, 200]];
			points.push(...this.trackAudioAnalysis.segments.map((s) => {
				return [
					this.secondsToWidth(s.start),
					200-this.loudnessToHeight(s.loudness_max),
				];
			}));
			points.push([1000, 200]);
			return points.map((p) => {
				return p.join(',');
			}).join(' ');
		},
	},
	methods: {
		secondsToWidth: function (seconds) {
			return seconds / this.trackAudioAnalysis.track.duration * 1000;
		},
		loudnessToHeight: function (loudness) {
			return (60+loudness) / 60 * 200;
		}
	},
}
</script>

<style lang="less" scoped>
svg {
	width: 100%;
	polygon {
		fill: var(--bs-secondary);
	}
}
</style>
