import Vue from 'vue';
import VueSpotifyAPI from './lib/spotify-api';
import App from './App.vue';
import router from './router';
import VueSocketIO from 'vue-socket.io';
import { createPinia, PiniaVuePlugin } from 'pinia'
import './lib/formatter';

const pinia = createPinia();

Vue.config.productionTip = false;
Vue.use(VueSpotifyAPI);
Vue.use(PiniaVuePlugin);
Vue.use(new VueSocketIO({
	connection: window.io(),
}));

Vue.config.devtools = true;

new Vue({
	router,
	render: (h) => h(App),
	pinia
}).$mount('#app');
