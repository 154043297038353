<template>
	<section class="toast-container position-fixed bottom-0 start-50 translate-middle-x mb-2">
		<transition-group name="toast" tag="div">
			<Toast v-for="(toast, index) in toasts" :key="toast.addedMS" :toast="toast" :index="index"></Toast>
		</transition-group>
	</section>
</template>

<script>
import Toast from '@/components/Toast.vue';
import { useToastStore } from '../stores/toast';

export default {
	name: 'Toasts',
	components: {
		Toast,
	},
	data: function () {
		return {
			toasts: useToastStore().toasts,
		};
	},
	created: function() {},
};
</script>
