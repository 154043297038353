import {
	defineStore
} from "pinia";

export const useValueStore = defineStore('values', {
	state: () => ({
		localDeviceID: null,
		isActiveDevice: false,
	}),
	getters: {
	},
	actions: {
	},
});
