<template>
	<section class="playstate mb-4 card">
		<div class="card-header">Controls
			<small v-if="playState && !valueStore.isActiveDevice" class="text-danger">Other device is active! Close other tabs before transferring playback!</small>
		</div>
		<div class="card-body">
			<template v-if="valueStore.localDeviceID">
				<div class="row mb-2">
					<div class="col">
						<div class="btn-group">
							<button
								title="Will start the playlist from the beginning"
								class="btn bi bi-play fs-2 btn-outline-primary"
								:disabled="!hasSongs"
								:class="{
									active: playState && !playState.paused,
								}"
								@click="startPlay">
							</button>
							<button
								title="Pause / Resume"
								class="btn bi bi-pause fs-2"
								:disabled="!playState"
								:class="{
									active: playState && playState.paused,
									'btn-outline-primary': playState,
									'btn-outline-secondary': !playState,
								}"
								@click="togglePause()">
							</button>
							<button
								title="Skip"
								class="btn bi bi-skip-forward fs-2"
								:disabled="!playState || isLastTrack"
								:class="{
									'btn-outline-primary': playState && !isLastTrack,
									'btn-outline-secondary': !playState || isLastTrack,
								}"
								@click="skipSong">
							</button>
						</div>
					</div>
					<!--
						EXTERNAL LINK NOT IN WEB PLAYSTATE
						<div class="col-auto" v-if="playState">
						<a class="d-input-block" target="_blank" :href="item.external_urls.spotify">
							<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
						</a>
					</div> -->
					<div class="col-auto" v-if="albumImage">
						<img :src="albumImage" />
					</div>
				</div>
				<template v-if="playState && item">
					<div>
						<router-link class="text-decoration-none" :to="`/track/${item.id}`" v-text="item.name"></router-link>
						–
						<ArtistsList :artists="item.artists" :link="true"></ArtistsList>
					</div>
					<div class="row">
						<div class="col-auto" v-text="progress_time"></div>
						<div class="col d-flex align-items-center">
							<div class="progress flex-fill">
								<div class="progress-bar"
									:style="{width: `${position / playState.duration * 100}%`}"
									:class="{'progress-bar-striped progress-bar-animated': !playState.paused}"
								></div>
							</div>
						</div>
						<div class="col-auto" v-text="duration_time"></div>
					</div>
				</template>
			</template>
			<div v-else>Connecting to Spotify Web Player …</div>
		</div>
	</section>
</template>

<script>
import { usePlayStateStore } from '../stores/playstate';
import { usePartyStore } from '../stores/party';
import { useValueStore } from '../stores/values';

import ArtistsList from './ArtistsList.vue'

export default {
	name: 'PlayState',
	props: {
		party: Object,
	},
	components: {
		ArtistsList
	},
	data: function () {
		return {
			now: Date.now(),
			interval: null,
			lastUpdated: Date.now(),
			playStateStore: usePlayStateStore(),
			partyStore: usePartyStore(),
			valueStore: useValueStore(),
		};
	},
	created: function created() {
		this.interval = setInterval(() => {
			this.now = Date.now();
		}, 1000);
	},
	beforeDestroy: function() {
		clearInterval(this.interval);
	},
	watch: {
		playState: function() {
			this.lastUpdated = Date.now();
		},
	},
	computed: {
		position: function() {
			if (!this.playState) {
				return 0;
			}

			if (this.playState.paused) {
				return this.playState.position;
			}

			return Math.min(this.now - this.lastUpdated + this.playState.position, this.playState.duration);
		},
		progress_time: function() {
			return this.formatTime(this.position);
		},
		duration_time: function() {
			if (this.playState) {
				return this.formatTime(this.playState.duration);
			} else {
				return '0:00';
			}
		},
		albumImage: function() {
			const images = this.item
				&& this.item.album
				&& this.item.album.images;
			if (!images || !images.length) {
				return null;
			}
			return images.find(i => i.size === 'SMALL').url;
		},
		playState: function () {
			return this.playStateStore.playState;
		},
		item: function () {
			return this.playState && this.playState.track_window.current_track;
		},
		isLastTrack: function () {
			return (this.playStateStore.songs.length - 1) === this.playStateStore.activeIndex;
		},
		hasSongs: function () {
			return this.playStateStore.songs.length > 0;
		},
	},
	methods: {
		startPlay: function () {
			this.playStateStore.party = this.party;
			this.playStateStore.songs = this.partyStore.songs;
			this.changePlayState('play');
		},
		changePlayState: function(state) {
			this.playStateStore.changePlayState(state);
		},
		formatTime: function(ms) {
			const min = Math.max(Math.floor(ms / 60000), 0);
			const sec = Math.max(Math.floor(ms / 1000) % 60, 0);
			return `${min}:${sec<10?'0'+sec:sec}`;
		},
		togglePause: function() {
			if (this.playState && !this.playState.paused) {
				this.changePlayState('pause');
			}
			if (this.playState && this.playState.paused) {
				this.changePlayState('resume');
			}
		},
		skipSong: function(){
			this.playStateStore.party = this.party;
			this.playStateStore.songs = this.partyStore.songs;
			this.changePlayState('skip');
		},
	},
};
</script>

<style lang="less" scoped>
.spotify-card-header-image {
	max-height: 1.5em;
}

</style>
