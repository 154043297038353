import {
	defineStore
} from "pinia";
import { handleStatusError, handleErrorToast } from "@/lib/fetchHandlers";
import { useToastStore } from "./toast";
import { usePlayStateStore } from "./playstate";

export const usePartyStore = defineStore('party', {
	state: () => ({
		party: null,
		songs: [],
	}),
	getters: {
	},
	actions: {
		addVote: function(song) {
			fetch(`/api/party/${this.party.id}/vote`,{
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					party_song_id: song.id,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					const toastStore = useToastStore();
					console.log('voted', response);
					this.songs = response.party_songs;
					toastStore.addToast({
						header: 'Vote added',
						text: `You voted for ${song.title}`,
						type: 'success',
						duration: 2000,
					});
				})
				.catch((err) => {
					this.songs = [];
					handleErrorToast(err, 'Add vote error')
				});
		},
		removeVote: function(song) {
			fetch(`/api/party/${this.party.id}/vote`, {
				method: 'delete',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					party_song_id: song.id,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					const toastStore = useToastStore();
					console.log('voted', response);
					this.songs = response.party_songs;
					toastStore.addToast({
						header: 'Vote removed',
						text: `You removed your vote for ${song.title}`,
						type: 'success',
						duration: 2000,
					});
				})
				.catch((err) => {
					this.songs = [];
					handleErrorToast(err, 'Remove vote error')
				});
		},
		addSong: function(song) {
			fetch(`/api/party/${this.party.id}/song`, {
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					spotify_id: song.id,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					console.log('added song', response);
					this.songs = response.party_songs;
				})
				.catch((err) => handleErrorToast(err, 'Add song error'));
		},
		fetchSongs: function() {
			const playStateStore = usePlayStateStore();
			return fetch(`/api/party/${this.party.id}/songs`)
				.then(handleStatusError)
				.then((response) => {
					console.log('received songs', response);
					this.songs = response.party_songs;
					if (playStateStore.party && playStateStore.party.id === this.party.id) {
						playStateStore.songs = this.songs;
					}
					return this.songs;
				}).catch((err) => {
					this.songs = [];
					handleErrorToast(err, 'Fetch songs error')
					return this.songs;
				});
		},
		removeSong: function(song) {
			fetch(`/api/party/${this.party.id}/song/${song.id}`, {
				method: 'delete',
				headers: {
					'Content-Type': 'application/json'
				},
			})
				.then(handleStatusError)
				.then((response) => {
					console.log('removed song', response);
					this.songs = response.party_songs;
				})
				.catch((err) => {
					this.songs = [];
					handleErrorToast(err, 'Remove song error')
				});
		},
		fetchParty: function(id) {
			return fetch(`/api/party/${id}`)
				.then(handleStatusError)
				.then((response) => {
					this.party = response.party;
					return this.party;
				})
				.catch((err) => {
					this.party = null;
					handleErrorToast(err, 'Fetch party error');
					return this.party;
				});
		},
	},
});
