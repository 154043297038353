var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"playstate mb-4 card"},[_c('div',{staticClass:"card-header"},[_vm._v("Controls "),(_vm.playState && !_vm.valueStore.isActiveDevice)?_c('small',{staticClass:"text-danger"},[_vm._v("Other device is active! Close other tabs before transferring playback!")]):_vm._e()]),_c('div',{staticClass:"card-body"},[(_vm.valueStore.localDeviceID)?[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bi bi-play fs-2 btn-outline-primary",class:{
								active: _vm.playState && !_vm.playState.paused,
							},attrs:{"title":"Will start the playlist from the beginning","disabled":!_vm.hasSongs},on:{"click":_vm.startPlay}}),_c('button',{staticClass:"btn bi bi-pause fs-2",class:{
								active: _vm.playState && _vm.playState.paused,
								'btn-outline-primary': _vm.playState,
								'btn-outline-secondary': !_vm.playState,
							},attrs:{"title":"Pause / Resume","disabled":!_vm.playState},on:{"click":function($event){return _vm.togglePause()}}}),_c('button',{staticClass:"btn bi bi-skip-forward fs-2",class:{
								'btn-outline-primary': _vm.playState && !_vm.isLastTrack,
								'btn-outline-secondary': !_vm.playState || _vm.isLastTrack,
							},attrs:{"title":"Skip","disabled":!_vm.playState || _vm.isLastTrack},on:{"click":_vm.skipSong}})])]),(_vm.albumImage)?_c('div',{staticClass:"col-auto"},[_c('img',{attrs:{"src":_vm.albumImage}})]):_vm._e()]),(_vm.playState && _vm.item)?[_c('div',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":`/track/${_vm.item.id}`},domProps:{"textContent":_vm._s(_vm.item.name)}}),_vm._v(" – "),_c('ArtistsList',{attrs:{"artists":_vm.item.artists,"link":true}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto",domProps:{"textContent":_vm._s(_vm.progress_time)}}),_c('div',{staticClass:"col d-flex align-items-center"},[_c('div',{staticClass:"progress flex-fill"},[_c('div',{staticClass:"progress-bar",class:{'progress-bar-striped progress-bar-animated': !_vm.playState.paused},style:({width: `${_vm.position / _vm.playState.duration * 100}%`})})])]),_c('div',{staticClass:"col-auto",domProps:{"textContent":_vm._s(_vm.duration_time)}})])]:_vm._e()]:_c('div',[_vm._v("Connecting to Spotify Web Player …")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }