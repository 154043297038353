<template>
	<section class="playlist-page">
		<Playlist :id="$route.params.id"></Playlist>
	</section>
</template>
<script>
import Playlist from '@/components/Playlist.vue';

export default {
	name: 'PlaylistPage',
	components: {
		Playlist,
	},
};
</script>
