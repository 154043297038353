<template>
	<section class="parties">
		<div class="card mb-4">
			<div class="card-header">Parties</div>
			<div class="list-group list-group-flush" v-if="parties && parties.length > 0">
				<router-link class="list-group-item list-group-item-action" v-for="party in parties" v-bind:key="party.id" :to="`/party/${party.id}`">
					<div class="row">
						<span class="col" v-text="party.name"></span>
						<span class="col-auto">{{ party.createdAt | formatDateTime }}</span>
					</div>
				</router-link>
			</div>
			<div v-else class="card-body">
				No parties found.
			</div>
		</div>
	</section>
</template>

<script>
import { handleStatusError, handleErrorToast } from '@/lib/fetchHandlers.js';

export default {
	name: 'Parties',
	data: function data() {
		return {
			parties: [],
		};
	},
	created: function() {
		fetch(`/api/party`)
			.then(handleStatusError)
			.then((data) => {
				console.log('fetched parties', data);
				this.parties = data.parties;
			}).catch((err) => handleErrorToast(err, 'Fetch party error'));
	},
};
</script>

<style lang="less" scoped>

</style>
