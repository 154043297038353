<template>
	<div class="py-2 border-top d-print-none sticky-bottom border-2 bg-gradient bg-secondary text-light" v-if="isDisplayComponent">
		<div class="container">
			<div class="row">
				<div class="col-12 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
					<div class="row align-items-center mb-1 border-bottom pb-2">
						<div class="col-auto" v-if="albumImage">
							<img class="album-image" :src="albumImage" />
						</div>
						<div class="col text-light">
							<router-link class="text-light text-decoration-none" :to="`/track/${item.id}`" v-text="item.name"></router-link>
							–
							<ArtistsList :artists="item.artists" classes="text-light" :link="true"></ArtistsList>
						</div>
						<!--<div class="col-auto">
							<a class="d-input-block" target="_blank" :href="item.external_urls.spotify">
								<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
							</a>
						</div> -->
					</div>
					<div class="row">
						<div class="col-auto" v-text="progress_time"></div>
						<div class="col d-flex align-items-center">
							<div class="progress flex-fill">
								<div class="progress-bar"
									:style="{width: `${position / playState.duration * 100}%`}"
									:class="{'progress-bar-striped progress-bar-animated': !playState.paused}"
								></div>
							</div>
						</div>
						<div class="col-auto" v-text="duration_time"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { usePlayStateStore } from '../stores/playstate';
import { useUserStore } from '../stores/user';
import { useValueStore } from '../stores/values';
import ArtistsList from './ArtistsList.vue'

export default {
	name: 'PlayStateSticky',
	props: {},
	components: {
		ArtistsList,
	},
	data: function () {
		return {
			now: Date.now(),
			interval: null,
			lastUpdated: Date.now(),
			playStateStore: usePlayStateStore(),
			userStore: useUserStore(),
			valueStore: useValueStore(),
		};
	},
	created: function created() {
		this.interval = setInterval(() => {
			this.now = Date.now();
		}, 1000);
	},
	beforeDestroy: function() {
		clearInterval(this.interval);
	},
	watch: {
		playState: function() {
			this.lastUpdated = Date.now();
		},
	},
	computed: {
		position: function() {
			if (!this.playState) {
				return 0;
			}

			if (this.playState.paused) {
				return this.playState.position;
			}

			return Math.min(this.now - this.lastUpdated + this.playState.position, this.playState.duration);
		},
		progress_time: function() {
			return this.formatTime(this.position);
		},
		duration_time: function() {
			if (this.playState) {
				return this.formatTime(this.playState.duration);
			} else {
				return '0:00';
			}
		},
		albumImage: function() {
			const images = this.item.album.images;
			if (!images || !images.length) {
				return null;
			}
			return images[images.length - 1].url;
		},
		playState: function () {
			return this.playStateStore.playState;
		},
		isDisplayComponent: function () {
			return this.userStore.user
				// && this.userStore.user.id
				&& this.playState
				&& this.item
		},
		item: function () {
			return this.playState && this.playState.track_window.current_track;
		},
	},
	methods: {
		changePlayState: function(state) {
			this.playStateStore.changePlayState(state);
		},
		formatTime: function(ms) {
			const min = Math.max(Math.floor(ms / 60000), 0);
			const sec = Math.max(Math.floor(ms / 1000) % 60, 0);
			return `${min}:${sec<10?'0'+sec:sec}`;
		},
		togglePause: function() {
			if (this.playState && !this.playState.paused) {
				this.changePlayState('pause');
			}
			if (this.playState && this.playState.paused) {
				this.changePlayState('resume');
			}
		}
	},
	sockets: {
		'party:playstate': function(data) {
			if (this.valueStore.isActiveDevice) {
				return; // only update for visitors
			}
			console.log('playstate changed', data);
			this.playStateStore.setPlayState(data.state, {
				post: false,
			});
		},
	},
};
</script>

<style lang="less" scoped>
.spotify-card-header-image {
	max-height: 1.5em;
}
.album-image {
	max-height: 2.5em;
}

</style>
