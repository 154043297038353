<template>
	<section class="artist mb-4" v-if="artist">
		<div class="row">
			<div class="col-md-4 col-xl-3 mb-4" v-if="artist.images && artist.images.length > 0">
				<img class="card-img img-fluid img-thumbnail" :src="artist.images[0].url" />
			</div>
			<div class="col-md mb-4">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col">Artist</div>
							<div class="col-auto">
								<a class="d-input-block" target="_blank" :href="artist.external_urls.spotify">
									<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
								</a>
							</div>
						</div>
					</div>
					<div class="card-body">
						<dl class="row mb-0">
							<dt class="col-sm-5 col-lg-3">Name</dt>
							<dd class="col-sm-7 col-lg-9" v-text="artist.name"></dd>
							<dt class="col-sm-5 col-lg-3">Followers</dt>
							<dd class="col-sm-7 col-lg-9">{{ artist.followers.total | formatNumber }}</dd>
							<dt class="col-sm-5 col-lg-3">Genres</dt>
							<dd class="col-sm-7 col-lg-9" v-text="artist.genres.join(', ')"></dd>
							<dt class="col-sm-5 col-lg-3">Popularity</dt>
							<dd class="col-sm-7 col-lg-9">
								<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Popularity: ${artist.popularity}/100`">
									<div class="progress-bar" role="progressbar" :style="`width: ${artist.popularity}%`"></div>
								</div>
							</dd>
						</dl>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>
<script>
import { useToastStore } from '../stores/toast';
import { useArtistStore } from '../stores/artist';

export default {
	name: 'Artist',
	props: {
		id: String,
	},
	data: function data() {
		return {
			toastStore: useToastStore(),
			artistStore: useArtistStore(),
			artist: null,
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: async function (val) {
				this.artist = await this.artistStore.fetch(val);
			}
		}
	}
};
</script>
<style lang="less" scoped>

.spotify-card-header-image {
	max-height: 1.5em;
}

</style>
