import * as SpotifyWebApi from 'spotify-web-api-node';

class VueSpotifyAPI {
	static install(Vue, options) {
		window.spotifyApi = Vue.spotifyApi = new SpotifyWebApi();
		Object.defineProperties(Vue.prototype, {
			spotifyApi: {
				get() {
					return Vue.spotifyApi;
				},
			},
		});
	}
}

export default VueSpotifyAPI;
