import {
	defineStore
} from "pinia";
import { handleErrorToast } from '../lib/fetchHandlers'

export const useTrackStore = defineStore('track', {
	state: () => ({
		tracks: {},
	}),
	getters: {
		get: (state) => {
			return (id) => state.tracks[id] || null;
		},
	},
	actions: {
		add (id, results) {
			this.tracks[id] = results;
		},
		async fetch (id) {
			if (this.tracks[id]) {
				return this.tracks[id];
			}
			return window.spotifyApi.getTrack(id)
				.then((data) => {
					this.add(id, data.body);
					return data.body;
				}, (err) => {
					handleErrorToast(err, 'Fetch track error');
					return null;
				});
		},
	},
});
