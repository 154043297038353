<template>
	<section class="party-form mb-4 card">
		<div class="card-header">Party</div>
		<div class="card-body">
			<div class="alert alert-info" v-if="!is_edit_mode">
				<ul>
					<li>Choose a name for your party.</li>
					<li>Select an existing playlist to use as fallback.</li>
					<li>If you don't select a playlist, you'll have to tick the box to create a new one.</li>
				</ul>
				The playlist will be modified directly in Spotify.
			</div>
			<form method="post" action="/api/party" @submit="submit($event)">
				<div class="row mb-2">
					<label class="col-form-label col-sm-4 text-sm-end" for="form-party-name">Party Name</label>
					<div class="col-sm-8">
						<input id="form-party-name" type="text" name="name" class="form-control" v-model="name" required autocomplete="off" />
					</div>
				</div>
				<div class="row">
					<label class="col-form-label col-sm-4 text-sm-end" for="form-party-description">Description</label>
					<div class="col-sm-8 mb-2">
						<textarea id="form-party-description" type="text" name="description" class="form-control" rows="5" v-model="description" placeholder="You can enter a markdown formatted description for your party"></textarea>
					</div>
					<div v-if="description" class="offset-sm-4 col-sm-8 mb-2 marked" v-html="$options.filters.formatMarkdown(description)"></div>
				</div>
				<div class="row mb-2" v-if="!is_edit_mode">
					<div class="offset-sm-4 col-sm-8">
						<div class="form-check">
							<input id="createCopy" type="checkbox" class="form-check-input"  v-model="create_new_playlist"/>
							<label for="createCopy" class="form-check-label">Create new playlist</label>
						</div>
					</div>
				</div>
				<div class="row mb-2" v-if="!create_new_playlist">
					<label for="form-spotify-playlist" class="col-form-label col-sm-4 text-sm-end">Spotify Playlist</label>
					<div class="col-sm-8">
						<select id="form-spotify-playlist" name="spotify_id" class="form-select" v-model="spotify_id" :required="!create_new_playlist">
							<option></option>
							<option v-for="playlist in playlists" :key="playlist.id" v-text="playlist.name" :value="playlist.id"></option>
						</select>
					</div>
				</div>

				<div class="row mb-2">
					<div class="offset-sm-4 col-sm-8">
						<div class="form-check">
							<input id="setStartEnd" type="checkbox" class="form-check-input"  v-model="is_set_start_end"/>
							<label for="setStartEnd" class="form-check-label">Set start and end time for voting</label>
						</div>
					</div>
				</div>
				<div class="row mb-2" v-if="is_set_start_end">
					<label class="col-form-label col-sm-4 text-sm-end">Voting Timeframe</label>
					<div class="col-sm-8">
						<div class="input-group">
							<input
								type="datetime-local"
								name="party_start"
								class="form-control"
								:class="{'is-invalid': !isDateOrderCorrect}"
								v-model="party_start"
								required />
							<input
								type="datetime-local"
								name="party_end"
								class="form-control"
								:class="{'is-invalid': !isDateOrderCorrect}"
								v-model="party_end"
								required />
						</div>
					</div>
				</div>

				<div class="row mt-3" v-if="is_edit_mode">
					<div class="col-sm-4 mb-2 mb-sm-0 offset-sm-4">
						<button class="form-control btn btn-warning" type="button" @click.prevent="$router.push(`/party/${party.id}`);">Cancel</button>
					</div>
					<div class="col-sm-4">
						<button
							class="form-control btn btn-primary"
							:disabled="(is_set_start_end && !isDateOrderCorrect)"
						>Save</button>
					</div>
				</div>
				<div class="row" v-else>
					<div class="offset-sm-4 col-sm-8">
						<button
							class="form-control btn btn-primary"
							:disabled="(is_set_start_end && !isDateOrderCorrect)"
						>Create</button>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>

<script>
import { handleStatusError, handleErrorToast } from '@/lib/fetchHandlers.js';
import { useToastStore } from '../stores/toast';

export default {
	name: 'PartyForm',
	data: function() {
		return {
			name: '',
			spotify_id: '',
			description: '',
			create_new_playlist: false,
			playlists: [],
			party: null,
			is_set_start_end: false,
			party_start: null,
			party_end: null,
			toastStore: useToastStore(),
		};
	},
	props: {
		id: Number,
	},
	computed: {
		is_edit_mode: function() {
			return this.party && true;
		},
		isDateOrderCorrect: function () {
			return this.party_start < this.party_end;
		},
	},
	methods: {
		submit: function(ev) {
			ev.preventDefault();
			if (this.is_edit_mode) {
				this.updateParty();
			} else {
				this.createParty();
			}
		},
		createParty: function() {
			fetch('/api/party', {
				method: 'post',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name: this.name,
					description: this.description,
					spotify_id: this.spotify_id,
					create_new_playlist: this.create_new_playlist,
					party_start: this.party_start,
					party_end: this.party_end,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					// console.log('this', this.$router, response);
					this.$router.push(`/party/${response.party.id}`);
				})
				.catch((err) => handleErrorToast(err, 'Create party error'));
		},
		updateParty: function() {
			fetch(`/api/party/${this.party.id}`, {
				method: 'put',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					name: this.name,
					description: this.description,
					spotify_id: this.spotify_id,
					party_start: this.party_start,
					party_end: this.party_end,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					this.$router.push(`/party/${response.party.id}`);
				})
				.catch((err) => handleErrorToast(err, 'Update party error'));
		},

		fetchParty: function() {
			fetch(`/api/party/${this.id}`)
				.then(handleStatusError)
				.then((response) => {
					this.party = response.party;
					this.name = this.party.name;
					this.description = this.party.description,
					this.spotify_id = this.party.spotify_id;

					if (this.party.party_start) {
						this.party_start = new Date(this.party.party_start).toISOString().replace(/\..+$/, '');
					}
					if (this.party.party_end) {
						this.party_end = new Date(this.party.party_end).toISOString().replace(/\..+$/, '');
					}
					if (this.party.party_start && this.party.party_end) {
						this.is_set_start_end = true;
					}
				})
				.catch((err) => {
					this.party = null;
					handleErrorToast(err, 'Fetch party error');
				});
		},
	},
	created: function() {
		this.spotifyApi.getUserPlaylists()
			.then((data) => {
				console.log('Retrieved playlists', data.body.items);
				this.playlists = data.body.items;
			}).catch(err => {
				console.log('Something went wrong!', err);
				this.toastStore.addToast({
					header: 'Fetch playlist error',
					text: err,
					type: 'danger',
				});
			});
		if (this.id) {
			this.fetchParty();
		}
	},
	watch: {
		is_set_start_end: function (val) {
			if (!val) {
				this.party_start = null;
				this.party_end = null;
			}
		},
	}
};
</script>
