<template>
	<div id="app" v-if="isLoginChecked">
		<Navigation></Navigation>
		<main class="main container">
			<template v-if="userStore.user || $route.name === 'LegalPage'">
				<transition name="page-slide" mode="out-in">
					<router-view class=""/>
				</transition>
				<SpotifyPlayer v-if="userStore.user.id"></SpotifyPlayer>
			</template>
			<template v-else>
				<About></About>
				<Login></Login>
			</template>
		</main>
		<FooterComponent></FooterComponent>
		<PlayStateSticky></PlayStateSticky>
		<Toasts></Toasts>
		<ConnectionStatus></ConnectionStatus>
	</div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap';
import Navigation from '@/components/Navigation.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import About from '@/components/About.vue';
import Login from '@/components/Login.vue';
import Toasts from '@/components/Toasts.vue';
import SpotifyPlayer from '@/components/SpotifyPlayer.vue';
import ConnectionStatus from '@/components/ConnectionStatus.vue';
import PlayStateSticky from '@/components/PlayStateSticky.vue';
import { useUserStore } from './stores/user';
import { useToastStore } from './stores/toast';


export default {
	name: 'App',
	components: {
		Navigation,
		FooterComponent,
		About,
		Login,
		Toasts,
		SpotifyPlayer,
		ConnectionStatus,
		PlayStateSticky,
	},
	props: {},
	data: function data() {
		return {
			isLoginChecked: false,
			userStore: useUserStore(),
			toastStore: useToastStore(),
		};
	},
	created: function created() {
		this.setColorScheme();
		this.userStore.fetchUser();
		console.log('userstrore', this.userStore);
	},
	methods: {
		setColorScheme: function () {
			if (window.matchMedia) {
				if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
					document.body.dataset.bsTheme = 'dark';
				}
				window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
					document.body.dataset.bsTheme = event.matches ? 'dark' : 'light';
				});
			}
		}
	},
	computed: {
		user: function () {
			return this.userStore.user;
		},
	},
	watch: {
		user: function(user) {
			if (user) {
				this.isLoginChecked = true;
				let refreshInMS = 3500;
				if (user.expires_in) {
					refreshInMS = user.expires_in * 1000 - 10000;
				} else if (user.token_expires) {
					refreshInMS = new Date(user.token_expires) - Date.now() - 10000;
				}
				if (refreshInMS < 0) {
					refreshInMS = 100;
				}
				this.spotifyApi.setAccessToken(user.access_token);
				console.log('refresh token in ms:', refreshInMS);
				setTimeout(() => {
					this.userStore.fetchUser();
				}, refreshInMS);
			} else {
				this.isLoginChecked = true;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import '../../node_modules/bootstrap/dist/css/bootstrap.css';
// @import '../../node_modules/bootstrap-dark-5/dist/css/bootstrap-dark-plugin.css';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

#app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.main {
	flex: 1 1 auto;
	position: relative;
}

.page-slide-enter-active, .page-slide-leave-active {
	transition: all .3s linear;
	// position: absolute
}

.page-slide-enter, .page-slide-leave-to {
	opacity: 0;
}

.page-slide-enter {
	transform: translateX(100%);
}

.page-slide-leave-to {
	transform: translateX(-100%);
}
</style>

<style lang="less">
body {
	overflow-y: scroll;
	overflow-x: hidden;
}
.marked {
	p {
		margin-bottom: 2px;
	}
}

</style>
