<template>
	<section class="album-page">
		<Album :id="$route.params.id"></Album>
	</section>
</template>
<script>
import Album from '@/components/Album.vue';

export default {
	name: 'AlbumPage',
	components: {
		Album,
	},
};
</script>
