<template>
	<div v-if="isNotConnected" class="connection-status position-fixed bottom-0 start-0 m-4 fs-1 text-center">
		<span class="bi bi-wifi-off"></span>
	</div>
</template>

<script>
import { useToastStore } from '../stores/toast';

export default {
	name: 'ConnectionStatus',
	components: {},
	props: {},
	data: function() {
		return {
			isConnected: this.$socket.connected,
			toastStore: useToastStore(),
		};
	},
	computed: {
		isNotConnected: function() {
			return !this.isConnected;
		},
	},
	sockets: {
		connect: function() {
			this.isConnected = true;
		},
		disconnect: function(reason) {
			this.isConnected = false;
			this.toastStore.addToast({
				header: 'Lost socket connection to server',
				text: reason,
				type: 'danger',
			});
		},

	},
};
</script>

<style lang="less" scoped>
.connection-status {
	color: var(--bs-white);
	border-radius: 50%;
	width: 1.5em;
	line-height: 1.5em;
	background: var(--bs-danger);
	span {
		animation: pulse 0.75s infinite alternate ease-in-out;
	}
}

@keyframes pulse {
	from {
		filter: brightness(1);
	}

	to {
		filter: brightness(0.8);
	}
}
</style>
