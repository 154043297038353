import { useToastStore } from "../stores/toast";

export async function handleStatusError(response) {
	if (!response.ok) {
		console.error('handleStatusError', response);
		const err = new Error(response.statusText);
		err.response = response;
		if (response.json) {
			err.response.data = await response.json();
		}
		throw err;
	}
	return response.json();
};

export function handleErrorToast(err, header) {
	const toastStore = useToastStore();
	let msg = err;
	if (err.response && err.response.data && err.response.data.error) {
		msg = err.response.data.error;
	}
	toastStore.addToast({
		header: header,
		text: msg,
		type: 'danger',
	});
};
