<template>
	<section v-if="qrImage">
		<img class="img-fluid img-thumbnail" :src="qrImage" />
	</section>
</template>
<script>
import QRCode from 'qrcode';

export default {
	name: 'QR',
	components: {
	},
	props: {
		text: String,
	},
	data: function() {
		return {
			qrImage: null,
		};
	},
	mounted: function() {
	},
	watch: {
		text: {
			immediate: true,
			handler: function () {
				QRCode.toDataURL(this.text, {
					scale: 8,
					margin: 1,
				}).then(url => {
					this.qrImage = url;
				}).catch(err => {
					console.error(err)
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
</style>
