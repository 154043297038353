<template>
	<section v-if="party">
		<div class="card mb-4">
			<div class="card-header">
				<div class="row">
					<div class="col">Party</div>
					<div class="col-auto" v-if="userStore.user.id">
						<div class="dropdown">
							<button class="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								Options
							</button>
							<ul class="dropdown-menu dropdown-menu-end">
								<li><router-link class="dropdown-item" :to="`/party/${party.id}/edit`">Edit party</router-link></li>
								<li><button type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#deletePartyModal">Delete party</button></li>
								<li><button type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#clearAllVotes">Clear all votes</button></li>
								<li><button type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#changeInviteCode">Change invite code</button></li>
								<li><router-link :to="`/party/${party.id}/codes`" class="dropdown-item">Manage personal codes</router-link></li>
							</ul>
							</div>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg mb-2">
						<dl class="row mb-0">
							<dt class="col-lg-4 text-lg-end">Name</dt>
							<dd class="col-lg-8" v-text="party.name"></dd>

							<template v-if="party.description">
								<dt class="col-lg-4 text-lg-end">Description</dt>
								<dd class="col-lg-8 marked" v-html="$options.filters.formatMarkdown(party.description)"></dd>
							</template>

							<template v-if="userStore.user.id">
								<dt class="col-lg-4 text-lg-end">Invite Code</dt>
								<dd class="col-lg-8">
									<span
										v-text="party.invite_code"
										:class="{
											'bg-secondary': !isDisplayCode,
											'text-secondary': !isDisplayCode
										}"
									></span>
									<span
										class="bi bi-eye mx-2"
										@click="(isDisplayCode = !isDisplayCode)"
										:class="{'text-muted': !isDisplayCode}"
										role="button"
									></span>
									<span
										class="bi bi-qr-code"
										@click="(isDisplayQRCode = !isDisplayQRCode)"
										:class="{'text-muted': !isDisplayQRCode}"
										role="button"
									></span>
								</dd>
							</template>
							<template v-if="(party.party_start && party.party_end)">
								<dt class="col-lg-4 text-lg-end">Voting Timeframe</dt>
								<dd class="col-lg-8">{{ party.party_start | formatDateTime }} – {{ party.party_end | formatDateTime }}</dd>
							</template>
							<dt class="col-lg-4 text-lg-end">Active visitors</dt>
							<dd class="col-lg-8">
								<transition name="slide-fade" mode="out-in">
									<span class="visitor-count" :key="visitorCount" v-text="visitorCount"></span>
								</transition>
							</dd>
							<dt class="col-lg-4 text-lg-end">Playlist</dt>
							<dd class="col-lg-8">
								<router-link :to="`/playlist/${party.spotify_id}`" class="text-decoration-none">Show</router-link>
							</dd>
						</dl>
					</div>
					<div class="col-lg-auto text-lg-end mb-2" v-if="userStore.user.id && qrImage && isDisplayQRCode">
						<img class="img-fluid img-thumbnail" :src="qrImage" />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div v-if="userStore.user.id" class="col-lg-6">
				<PlayState :party="party" @updatePlayState="fetchPlayState" />
			</div>
			<div v-if="isVotingActive" class="col-lg-6">
				<Search :partyId="id" :party="party" @addSong="addSong" />
			</div>
		</div>

		<PartySongs v-if="isVotingActive" ref="partySongs" :party="party"></PartySongs>
		<div v-else class="alert alert-warning" role="alert">
			<h4>Voting not active</h4>
			Voting will be enabled from
			<strong>{{ new Date(party.party_start) | formatDateTime }}</strong>
			to
			<strong>{{ new Date(party.party_end) | formatDateTime }}</strong>
		</div>

		<template v-if="userStore.user.id">
			<div class="modal fade" id="deletePartyModal" aria-hidden="true" >
				<div class="modal-dialog">
					<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Delete party</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Are you sure you want to delete the party?<br />
						The Spotify playlist will stay intact.
					</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-danger" data-bs-dismiss="modal"  @click="deleteParty">Delete party</button>
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</div>

			<div class="modal fade" id="clearAllVotes" aria-hidden="true" >
				<div class="modal-dialog">
					<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Clear all votes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Are you sure you want to clear all votes?<br />
					</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-danger" data-bs-dismiss="modal"  @click="clearAllVotes">Clear votes</button>
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</div>

			<div class="modal fade" id="changeInviteCode" aria-hidden="true" >
				<div class="modal-dialog">
					<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Change invite code</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Are you sure you want to generate a new invite code?<br />
						The old invite code will no longer work if you do.
					</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="changeInviteCode">Create code</button>
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						</div>
					</div>
				</div>
			</div>

		</template>
	</section>
</template>

<script>
import QRCode from 'qrcode';
import PartySongs from './PartySongs.vue';
import Search from './Search.vue';
import PlayState from '@/components/PlayState.vue';
import { handleStatusError, handleErrorToast } from '@/lib/fetchHandlers.js';
import { useUserStore } from '../stores/user';
import { useToastStore } from '../stores/toast';
import { useValueStore } from '../stores/values';
import { usePlayStateStore } from '../stores/playstate';
import { usePartyStore } from '../stores/party'

export default {
	name: 'Party',
	components: {
		PartySongs,
		Search,
		PlayState,
	},
	props: {
		id: String,
	},
	data: function() {
		return {
			party: null,
			qrImage: null,
			visitorCount: 0,
			isDisplayCode: false,
			isDisplayQRCode: false,
			userStore: useUserStore(),
			toastStore: useToastStore(),
			valueStore: useValueStore(),
			playStateStore: usePlayStateStore(),
			partyStore: usePartyStore(),
		};
	},
	mounted: function() {
	},
	watch: {
		id: {
			immediate: true,
			handler: function () {
				this.fetchParty();
			}
		},
		party: function() {
			this.$socket.emit('party:join', {
				party_id: this.id,
			}, (data) => {
				if (data.success) {
					console.log('joined party socket');
					this.fetchPlayState();
				}
			});
			QRCode.toDataURL(`https://playlist.one/invite/${this.party.invite_code}`, {
				scale: 8,
				margin: 1,
			}).then(url => {
				this.qrImage = url;
			}).catch(err => {
				console.error(err)
			});
		}
	},
	computed: {
		isVotingActive: function () {
			const now = new Date();
			return this.party
				&& (
					this.userStore.user.id
					|| (this.party.party_start === null && this.party.party_end === null)
					|| (new Date(this.party.party_start) <= now && new Date(this.party.party_end) >= now)
				);
		},
		playState: function () {
			if (this.party && this.playStateStore.playState) {
				return this.playStateStore.playState;
			}
			return null;
		},
	},
	sockets: {
		connect: function() {
			this.$socket.emit('party:join', {
				party_id: this.id,
			});
		},
		playlistChanged: function(data) {
			console.log('playlist changed', data);
			if (!this.$refs.partySongs) {
				return;
			}
			this.$refs.partySongs.fetchSongs();
		},
		'party:playstate': function(data) {
			if (this.valueStore.isActiveDevice) {
				return; // only update for visitors
			}
			console.log('playstate changed', data);
			this.playStateStore.setPlayState(data.state, {
				post: false,
			});
		},
		'party:requestplaystate': function(data) {
			if (!this.valueStore.isActiveDevice) {
				return; // only answer request on active device
			}
			this.fetchPlayState();
		},
		'party:visitors': function(data) {
			console.log('visitors changed', data);
			this.visitorCount = data.count;
		},
	},
	methods: {
		addSong: function(song) {
			this.partyStore.addSong(song);
		},
		fetchParty: async function() {
			this.party = await this.partyStore.fetchParty(this.id);
		},
		fetchPlayState: function() {
			if (!this.party) {
				return;
			}
			this.playStateStore.party = this.party;
			this.playStateStore.fetchPlayState();
		},
		deleteParty: function() {
			fetch(`/api/party/${this.id}`, {
				method: 'delete',
			})
				.then(handleStatusError)
				.then((response) => {
					this.$router.push(`/`);
				})
				.catch((err) => handleErrorToast(err, 'Delete party error'));
		},
		clearAllVotes: function() {
			fetch(`/api/party/${this.id}/votes`, {
				method: 'delete',
			})
				.then(handleStatusError)
				.then((response) => {})
				.catch((err) => handleErrorToast(err, 'Clear all votes error'));
		},
		changeInviteCode: function() {
			fetch(`/api/party/${this.id}/invitecode`, {
				method: 'put',
			})
				.then(handleStatusError)
				.then((response) => {
					this.party = response.party;
				})
				.catch((err) => handleErrorToast(err, 'Change party invite error'));
		},
	},
	beforeDestroy: function() {
		this.$socket.emit('party:leave', {
			party_id: this.id,
		});
	}
};
</script>

<style lang="less" scoped>
.spotify-icon {
	max-height: 2em;
}
.spotify-card-header-image {
	max-height: 1.5em;
}

.visitor-count {
	display: inline-block;
}

.slide-fade-enter-active, .slide-fade-leave-active {
	transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
	transform: translateX(2em);
	opacity: 0;
}
</style>
