<template>
	<div class="search mb-4">
		<div class="card">
			<label for="q" class="card-header">
				<div class="row">
					<span class="col">Search a song to add to the party</span>
					<span v-if="isQueryInFlight" class="col-auto">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</span>
				</div>
			</label>
			<form @submit="handleSubmit" class="card-body">
				<div class="input-group">
					<input
						id="q"
						placeholder="Song title or artist name"
						class="form-control"
						type="text"
						v-model="q"
						@input="q = $event.target.value;"
						@change="q = $event.target.value;"
						autocomplete="off" />
					<button
						v-if="searchStore.searchQuery"
						type="button"
						@click="restoryPreviousQuery"
						class="btn btn-outline-primary bi bi-clock-history fs-4 lh-1">
					</button>
					<button
						type="button"
						@click="clearQuery"
						class="btn btn-primary"
						:disabled="q.length === 0">
						Clear
					</button>
				</div>
			</form>
		</div>
		<transition name="fade">
			<div class="container results-wrapper" v-if="tracks.length">
				<ul class="results bg-light border list-group">
					<li class="list-group-item" v-for="track in tracks" :key="track.id">
						<div class="row">
							<div @click="addToParty(track)" class="col add-row" role="button">
								<span class="icon me-3 bi bi-plus-circle-fill text-primary fs-5"></span>
								<span v-text="track.name"></span>
								–
								<ArtistsList :artists="track.artists" :link="false"></ArtistsList>
							</div>
							<div class="col-auto">
								<router-link class="ms-3 bi bi-info-circle-fill text-secondary fs-5" :to="`/track/${track.id}`"></router-link>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
import { debounce } from 'throttle-debounce';
import { useToastStore } from '../stores/toast';
import { useSearchStore } from '../stores/search';
import ArtistsList from './ArtistsList.vue';

export default {
	name: 'Search',
	data: function data() {
		return {
			q: '',
			tracks: [],
			toastStore: useToastStore(),
			searchStore: useSearchStore(),
			isQueryInFlight: false,
		};
	},
	components: {
		ArtistsList
	},
	props: {
		partyId: Number,
		party: Object,
	},
	beforeDestroy: function() {
		this.searchStore.searchQuery = this.q;
	},
	watch: {
		q: debounce(750, function(val) {
			if (val.length < 3) {
				this.tracks = [];
				return;
			}
			const cachedResults = this.searchStore.getResult(val);
			if (cachedResults.length > 0) {
				this.tracks = cachedResults;
				return;
			}
			this.isQueryInFlight = true;
			this.spotifyApi.search(val, ['track'], {
				limit: 50,
				market: this.party.market,
			}).then((data) => {
				console.log('Retrieved results', data);
				this.isQueryInFlight = false;
				this.tracks = data.body.tracks.items;
				this.searchStore.addResults(this.q, this.tracks);
			}).catch(err => {
				console.log('Something went wrong!', err);
				this.isQueryInFlight = false;
				this.toastStore.addToast({
					header: 'Search error',
					text: err,
					type: 'danger',
				});
			});
		})
	},
	methods: {
		addToParty: function(song) {
			this.searchStore.searchQuery = this.q;
			this.q = '';
			this.tracks = [];
			this.$emit('addSong', song);
		},
		clearQuery: function() {
			this.searchStore.searchQuery = this.q;
			this.q = '';
			this.tracks = [];
		},
		restoryPreviousQuery: function() {
			this.q = this.searchStore.searchQuery;
			this.tracks = this.searchStore.getResult(this.q);

			this.searchStore.searchQuery = '';
		},
		handleSubmit: function(ev) {
			ev.preventDefault();
		},
	}
};
</script>

<style lang="less" scoped>
.results-wrapper {
	position: relative;
	z-index: 2000;
}

.results {
	position: absolute;
	right: 0;
	left: 0;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

.add-row {
	&:hover {
		.icon {
			&::before {
				transform: rotate(90deg);
			}
		}
	}
	.icon {
		&::before {
			transition: 0.3s transform ease-in-out;
		}
	}
}
</style>
