<template>
	<tr
		:class="{'table-primary': playState && trackPlaying && trackPlaying.id === song.spotify_id && !playState.paused,'table-warning': playState && trackPlaying && trackPlaying.id === song.spotify_id && playState.paused}"
	>
		<td v-text="song.position" class="text-end"></td>
		<td class="py-0 d-flex"><router-link class="py-2 flex-fill" :to="`/track/${song.spotify_id}`">
			<span v-text="song.title"></span>
			<small> – {{song.artist}}</small>
		</router-link></td>
		<td class="text-end vote-toggle text-nowrap">
			<span v-text="song.votes" class="me-2"></span>
			<span v-if="song.voted" @click="removeVote(song)" class="bi bi-hand-thumbs-up-fill me-2"></span>
			<span v-if="!song.voted" @click="addVote(song)" class="bi bi-hand-thumbs-up me-2"></span>
			<span v-if="userStore.user.id && (trackPlaying && trackPlaying.id !== song.spotify_id || !trackPlaying)" @click="removeSong(song)" class="bi bi-trash"></span>
		</td>
	</tr>
</template>

<script>
import { usePartyStore } from '../stores/party';
import { useUserStore } from '../stores/user';


export default {
	name: 'PartySongs',
	props: {
		party: Object,
		song: Object,
		playState: Object,
		trackPlaying: Object,
	},
	data: function data() {
		return {
			partyStore: usePartyStore(),
			userStore: useUserStore(),
		};
	},
	methods: {
		addVote: function(song) {
			this.partyStore.addVote(song);
		},
		removeVote: function(song) {
			this.partyStore.removeVote(song);
		},
		addSong: function(song) {
			this.partyStore.addSong(song);
		},
		removeSong: function(song) {
			if (confirm('Are you sure you want to remove this song?')) {
				this.partyStore.removeSong(song);
			}
		},
	}
};
</script>

<style lang="less" scoped>
a {
	text-decoration: none;
}

.vote-toggle {
	.bi {
		cursor: pointer;
	}
	.bi-hand-thumbs-up-fill {
		color: var(--bs-danger);
	}
	.bi-hand-thumbs-up {
		color: var(--bs-gray);
		&:hover {
			color: var(--bs-dark);
		}
	}
	.bi-trash {
		color: var(--bs-gray);
		&:hover {
			color: var(--bs-dark);
		}
	}
}
</style>
