import {
	defineStore
} from "pinia";
import { handleErrorToast } from '../lib/fetchHandlers'

export const useTrackAudioAnalysisStore = defineStore('trackAudioAnalysis', {
	state: () => ({
		trackAudioAnalysis: {},
	}),
	getters: {
		get: (state) => {
			return (id) => state.trackAudioAnalysis[id] || null;
		},
	},
	actions: {
		add (id, results) {
			this.trackAudioAnalysis[id] = results;
		},
		async fetch (id) {
			if (this.trackAudioAnalysis[id]) {
				return this.trackAudioAnalysis[id];
			}
			return window.spotifyApi.getAudioAnalysisForTrack(id)
				.then((data) => {
					console.log('analysis', data.body);
					this.add(id, data.body);
					return data.body;
				}, (err) => {
					handleErrorToast(err, 'Fetch audio analysis error');
					return null;
				});
		},
	},
});
