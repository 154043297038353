import {
	defineStore
} from "pinia";
import { handleErrorToast } from '../lib/fetchHandlers'

export const useAlbumStore = defineStore('album', {
	state: () => ({
		albums: {},
	}),
	getters: {
		get: (state) => {
			return (id) => state.albums[id] || null;
		},
	},
	actions: {
		add (id, results) {
			this.albums[id] = results;
		},
		async fetch (id) {
			if (this.albums[id]) {
				return this.albums[id];
			}
			return window.spotifyApi.getAlbum(id)
				.then((data) => {
					this.add(id, data.body);
					return data.body;
				}, (err) => {
					handleErrorToast(err, 'Fetch album error');
					return null;
				});
		},
	},
});
