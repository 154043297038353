<template>
	<section class="artist-albums mb-4 card" v-if="albums">
		<div class="card-header">Albums</div>
		<div class="albums list-group list-group-flush">
			<router-link v-for="album in albums" :key="album.id" class="list-group-item" :to="`/album/${album.id}`" v-text="album.name"></router-link>
		</div>
	</section>
</template>
<script>
import { useToastStore } from '../stores/toast';

export default {
	name: 'ArtistAlbums',
	props: {
		id: String,
	},
	data: function data() {
		return {
			albums: null,
			toastStore: useToastStore(),
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: function () {
				this.spotifyApi.getArtistAlbums(this.id, {
					limit: 50,
				}).then((data) => {
					console.log('Retrieved ArtistAlbums', data);
					this.albums = data.body.items;
				}).catch(err => {
					console.log('Something went wrong!', err);
					this.toastStore.addToast({
						header: 'Fetch artist albums error',
						text: err,
						type: 'danger',
					});
				});
			}
		}
	},
};
</script>
