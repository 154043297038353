<template>
	<section class="party-codes">
		<div class="mb-4 card d-print-none" v-if="party">
			<div class="card-header">
				<div class="row">
					<strong class="col" v-text="party.name"></strong>
					<div class="col-auto">Party Invite Codes</div>
				</div>
			</div>
			<div class="card-body">
				<form @submit="createTokens">
					<div class="input-group mb-3">
						<span class="input-group-text">Add Invite Codes</span>
						<input type="number" min="1" max="50" class="form-control" v-model="request_count">
						<button class="btn btn-outline-secondary" type="submit">Add</button>
					</div>
				</form>
				<div class="row">
					<div class="col">
						<div class="btn-group">
							<router-link class="btn btn-outline-primary" :to="`/party/${party.id}`">Back</router-link>
							<button class="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#deletePartyCodesModal">Remove all codes</button>
							<a class="btn btn-outline-primary" :download="`${party.name}.csv`" :href="csvURL">Download CSV</a>
						</div>
					</div>
					<div class="col-auto">
						<div class="input-group">
							<button class="btn btn-outline-primary bi bi-table" @click="isDisplayQR = false" :disabled="!isDisplayQR"></button>
							<button class="btn btn-outline-primary bi bi-qr-code" @click="isDisplayQR = true" :disabled="isDisplayQR"></button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card mb-4" v-if="party_tokens.length > 0 && !isDisplayQR">
			<div class="card-header">
				<div class="row">
					<div class="col">Party Invite Codes ({{ party_tokens.length }})</div>
				</div>
			</div>
			<table class="table table-striped table-hover table-borderless mb-0">
				<thead>
					<tr>
						<th>Code</th>
						<th>Created At</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="token in sortedTokens" :key="token.id">
						<td v-text="token.token"></td>
						<td>{{ token.created_at | formatDateTime }}</td>
						<!-- <td><button class="btn btn-danger" disabled>X</button></td> -->
					</tr>
				</tbody>
			</table>
		</div>


		<div v-if="party_tokens.length > 0 && isDisplayQR" class="list-token-qr">
			<div v-for="token in sortedTokens" :key="token.id" class="d-inline-block w-50 p-3 mb-4 text-center token-qr">
				<QR :text="`https://playlist.one/invite/${token.token}`"></QR>
				<div>Visit playlist.one and enter the invite code: <strong v-text="token.token"></strong></div>
			</div>
		</div>

		<div class="modal fade" id="deletePartyCodesModal" aria-hidden="true" >
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Delete all invite codes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						Are you sure you want to delete all invite codes?<br />
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="removeAllTokens">Delete codes</button>
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import { handleStatusError, handleErrorToast } from '@/lib/fetchHandlers.js';
import QR from './QR.vue';

export default {
	name: 'PartyCodes',
	components: {
		QR,
	},
	data: function() {
		return {
			party: null,
			party_tokens: [],
			request_count: 1,
			isDisplayQR: false,
		};
	},
	props: {
		id: Number,
	},
	computed: {
		sortedTokens: function () {
			return this.party_tokens.map((a) => {
				return {...a,
					created_at: new Date(a.createdAt),
					updated_at: new Date(a.updatedAt),
				};
			}).sort((a, b) => {
				return b.created_at - a.created_at;
			});
		},
		csvURL: function () {
			return `data:text/plain;charset=utf-8,${encodeURIComponent(this.sortedTokens.map((t) => {
				return `${t.token};${t.createdAt}`;
			}).join('\n'))}`;
		},
	},
	methods: {
		fetchParty: function() {
			fetch(`/api/party/${this.id}`)
				.then(handleStatusError)
				.then((response) => {
					this.party = response.party;
					this.fetchPartyTokens();
				})
				.catch((err) => {
					this.party = null;
					handleErrorToast(err, 'Fetch party error');
				});
		},
		fetchPartyTokens: function() {
			fetch(`/api/party/${this.id}/tokens`)
				.then(handleStatusError)
				.then((response) => {
					this.party_tokens = response.party_tokens;
				})
				.catch((err) => {
					this.party_tokens = [];
					handleErrorToast(err, 'Fetch tokens error');
				});
		},
		createTokens: function (ev) {
			ev.preventDefault();
			console.log('request_count', this.request_count);
			fetch(`/api/party/${this.id}/tokens`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					count: this.request_count,
				}),
			})
				.then(handleStatusError)
				.then((response) => {
					this.party_tokens.push(...response.party_tokens);
				})
				.catch((err) => {
					this.party_tokens = [];
					handleErrorToast(err, 'Create tokens error');
				});
		},
		removeAllTokens: function () {
			fetch(`/api/party/${this.id}/tokens`, {
				method: 'DELETE',
			})
				.then(handleStatusError)
				.then(() => {
					this.party_tokens = [];
				})
				.catch((err) => {
					handleErrorToast(err, 'Delete tokens error');
				});
		},
	},
	created: function() {
		if (this.id) {
			this.fetchParty();
		}
	},
};
</script>

<style lang="less" scoped>
@media print {
	.list-token-qr {
		width: 210mm; // A4
	}
	.token-qr {
		break-inside: avoid;
	}
}
</style>
