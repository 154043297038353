<template>
	<section class="artist-top-tracks mb-4 card" v-if="tracks">
		<div class="card-header">Top Tracks</div>
		<div class="tracks list-group list-group-flush">
			<router-link v-for="track in tracks" :key="track.id" class="list-group-item" :to="`/track/${track.id}`" v-text="track.name"></router-link>
		</div>
	</section>
</template>
<script>
import { useToastStore } from '../stores/toast';

export default {
	name: 'ArtistTopTracks',
	props: {
		id: String,
	},
	data: function data() {
		return {
			tracks: null,
			toastStore: useToastStore(),
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: function () {
				this.spotifyApi.getArtistTopTracks(this.id, 'DE')
					.then((data) => {
						console.log('Retrieved ArtistTopTracks', data);
						this.tracks = data.body.tracks;
					}).catch(err => {
						console.log('Something went wrong!', err);
						this.toastStore.addToast({
							header: 'Fetch artist top tracks error',
							text: err,
							type: 'danger',
						});

					});
			},
		},
	},
};
</script>
