<template>
	<div class="toast show mb-2" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="toast-header" :class="headerClasses">
			<strong class="me-auto" v-text="toast.header"></strong>
			<button type="button" class="btn-close" :class="headerBtnClasses" aria-label="Close" @click="destroy"></button>
		</div>
		<div class="toast-body" v-text="toast.text"></div>
	</div>
</template>

<script>
import { useToastStore } from '../stores/toast';

export default {
	name: 'Toast',
	components: {},
	props: {
		toast: Object,
		index: Number,
	},
	data: function() {
		return {
			timeout: null,
			toastStore: useToastStore(),
		};
	},
	created: function() {
		this.timeout = setTimeout(this.destroy.bind(this), this.toast.duration);
	},
	computed: {
		headerClasses: function() {
			switch(this.toast.type) {
				case 'primary':
					return 'bg-primary text-white';
				case 'secondary':
					return 'bg-secondary text-white';
				case 'success':
					return 'bg-success text-white';
				case 'danger':
					return 'bg-danger text-white';
				case 'warning':
					return 'bg-warning text-dark';
				case 'info':
					return 'bg-info text-dark';
				case 'light':
					return 'bg-light text-dark';
				case 'dark':
					return 'bg-dark text-white';
				default:
					return '';
			}
		},
		headerBtnClasses: function() {
			switch(this.toast.type) {
				case 'primary':
				case 'secondary':
				case 'success':
				case 'danger':
				case 'dark':
					return 'btn-close-white';
				default:
					return '';
			}
		},
	},
	methods: {
		destroy: function() {
			this.toastStore.removeToast(this.index);
		},
	},
	beforeDestroy: function() {
		clearTimeout(this.timeout);
	}
};
</script>

<style lang="less" scoped>
.toast {
	transition: all 0.3s;
}
.toast-enter-active, .toast-leave-active {
	opacity: 1;
	transform: translateX(0%);
}
.toast-enter, .toast-leave-to {
	opacity: 0;
}
.toast-enter {
	transform: translateX(100%);
}
.toast-leave-to {
	transform: translateX(-100%);
}
</style>
