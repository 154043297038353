<template>
	<section class="track-page">
		<Track :id="$route.params.id"></Track>
	</section>
</template>
<script>
import Track from '@/components/Track.vue';

export default {
	name: 'TrackPage',
	components: {
		Track,
	},
};
</script>
