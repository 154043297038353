<template>
	<span class="artists">
		<template v-if="link">
			<router-link
				v-for="artist in artists"
				:key="artist"
				:to="`/artist/${artistId(artist)}`"
				class="artist text-decoration-none"
				:class="classes">
				<small v-text="artist.name"></small>
			</router-link>
		</template>
		<template v-else>
			<small
				v-for="artist in artists"
				:key="artist"
				:to="`/artist/${artistId(artist)}`"
				class="artist text-decoration-none"
				:class="classes"
				v-text="artist.name">
			</small>
		</template>
	</span>
</template>

<script>
export default {
	name: 'ArtistsList',
	props: {
		artists: String,
		classes: String,
		link: Boolean,
	},
	methods: {
		artistId: function (artist) {
			if (artist.id) {
				return artist.id;
			}
			if (artist.uri) {
				return artist.uri.replace('spotify:artist:', '');
			}
		},
	},
}
</script>


<style lang="less" scoped>
.artists {
	.artist {
		&:after {
			content: ', ';
		}
		&:last-child {
			&:after {
				content: none;
				margin-right: 0;
			}
		}
	}
}
</style>
