import {
	defineStore
} from "pinia";

import { useUserStore } from './user';
import { useValueStore } from './values';
import { handleStatusError, handleErrorToast } from '../lib/fetchHandlers'

export const usePlayStateStore = defineStore('playstate', {
	state: () => ({
		playState: null,
		songs: [],
		activeTrack: null,
		party: null,
	}),
	getters: {
		activeIndex: function (state) {
			return state.songs.findIndex(el => state.activeTrack && el.id === state.activeTrack.id);
		},
	},
	actions: {
		setPlayState: function (ps, opts) {
			if (!ps) {
				return;
			}
			this.playState = ps;
			const userStore = useUserStore();
			const valueStore = useValueStore();
			console.log('play state store, setPlayState', ps);
			if (!userStore.user.id) {
				console.log('play state store, not creator');
				return;
			}

			if (this.playState.paused === true && this.playState.position === 0 && valueStore.isActiveDevice) {
				console.log('should play next song');
				this.changePlayState('skip');
			}

			if (opts.post) {
				this.postPlayStateToAPI();
			}
		},
		postPlayStateToAPI: function () {
			console.log('play state store, post to api');
			const userStore = useUserStore();
			if (!userStore.user.id) {
				return;
			}
			return fetch(`/api/party/${this.party.id}/playstate`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({state: this.playState}),
			})
				.then(handleStatusError)
				.catch((err) => {
					handleErrorToast(err, 'Post play state error')
				});
		},
		fetchPlayState: function() {
			const userStore = useUserStore();
			const valueStore = useValueStore();
			if (!userStore.user) {
				return;
			}
			if (valueStore.isActiveDevice) { // is creator
				if (window.player) {
					window.player.getCurrentState().then((state) => {
						this.setPlayState(state, {
							post: valueStore.isActiveDevice,
						});
					});
				}
			} else { // is visitor or secondary tab of creator
				fetch(`/api/party/${this.party.id}/playstate`) // response will come via websocket
					.then(handleStatusError)
					.catch((err) => {
						this.playState = null;
						handleErrorToast(err, 'Fetch play state error')
					});
			}
		},
		changePlayState: function(state) {
			const valueStore = useValueStore();
			switch (state) {
				case 'play':
					this.activeTrack = this.songs[0];
					valueStore.isActiveDevice = true;
					window.spotifyApi.play({
						uris: [`spotify:track:${this.activeTrack.spotify_id}`],
						device_id: valueStore.localDeviceID,
					}).catch(handleErrorToast);
					break;
				case 'skip':
					if (this.songs.length === this.activeIndex + 1) {
						// last song reached
						return;
					}
					this.activeTrack = this.songs[this.activeIndex + 1];
					valueStore.isActiveDevice = true;
					if (this.activeTrack) {
						window.spotifyApi.play({
							uris: [`spotify:track:${this.activeTrack.spotify_id}`],
							device_id: valueStore.localDeviceID,
						}).catch(handleErrorToast);
					}
					break;
				case 'pause':
					window.spotifyApi.pause({}).catch(handleErrorToast);
					break;
				case 'resume':
					window.spotifyApi.play({}).catch(handleErrorToast);
					break;
			}
			// fetch(`/api/party/${this.party.id}/playstate`, {
			// 	method: 'post',
			// 	headers: {
			// 		'Content-Type': 'application/json'
			// 	},
			// 	body: JSON.stringify({
			// 		state,
			// 		device_id,
			// 		track_id,
			// 	}),
			// })
			// 	.then(handleStatusError)
			// 	.then((response) => {
			// 		console.log('changed state', state, response);
			// 		// state will be sent in socket
			// 	})
			// 	.catch((err) => handleErrorToast(err, 'Change play state error'));
		},
	},
});
