<template>
	<section class="party-edit-page">
		<PartyForm :id="$route.params.id"></PartyForm>
	</section>
</template>
<script>
import PartyForm from '../components/PartyForm.vue';

export default {
	name: 'PartyEditPage',
	components: {
		PartyForm,
	},
};
</script>
