import Vue from 'vue';
import { marked } from 'marked';
import insane from 'insane';

Vue.filter('formatDate', function(value) {
	if (value) {
		return new Intl.DateTimeFormat(navigator.language, {
			dateStyle: "short"
		}).format(new Date(value));
	}
	return '';
});

Vue.filter('formatDateTime', function(value) {
	if (value) {
		return new Intl.DateTimeFormat(navigator.language, {
			timeStyle: "short",
			dateStyle: "short"
		}).format(new Date(value));
	}
	return '';
});

Vue.filter('formatNumber', function(value) {
	if (value) {
		return new Intl.NumberFormat(navigator.language).format(value);
	}
	return '';
});

Vue.filter('formatMarkdown', function(value) {
	if (value) {
		return insane(marked.parse(value));
	}
	return '';
});
