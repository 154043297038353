<template>
	<section v-if="album">
		<div class="row">
			<div class="col-md-4 col-xl-3 mb-4" v-if="album.images && album.images.length > 0">
				<img class="card-img img-fluid img-thumbnail" :src="album.images[0].url" />
			</div>
			<div class="col-md mb-4">
				<div class="card">
					<div class="card-header">
						<div class="row">
							<div class="col">Album</div>
							<div class="col-auto">
								<a class="d-input-block" target="_blank" :href="album.external_urls.spotify">
									<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
								</a>
							</div>
						</div>
					</div>
					<div class="card-body">
						<dl class="row mb-0">
							<dt class="col-sm-5 col-lg-3">Name</dt>
							<dd class="col-sm-7 col-lg-9" v-text="album.name"></dd>
							<dt class="col-sm-5 col-lg-3">Artists</dt>
							<dd class="col-sm-7 col-lg-9">
								<ArtistsList :artists="album.artists" :link="true"></ArtistsList>
							</dd>
							<dt class="col-sm-5 col-lg-3">Release Date</dt>
							<dd class="col-sm-7 col-lg-9">{{ album.release_date | formatDate }}</dd>
							<dt class="col-sm-5 col-lg-3">Track #</dt>
							<dd class="col-sm-7 col-lg-9" v-text="album.total_tracks"></dd>
							<dt class="col-sm-5 col-lg-3">Label</dt>
							<dd class="col-sm-7 col-lg-9" v-text="album.label"></dd>
							<dt class="col-sm-5 col-lg-3">Popularity</dt>
							<dd class="col-sm-7 col-lg-9">
								<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Popularity: ${album.popularity}/100`">
									<div class="progress-bar" role="progressbar" :style="`width: ${album.popularity}%`"></div>
								</div>
							</dd>
						</dl>
					</div>
				</div>
			</div>
		</div>

		<div class="album mb-4 card">
			<div class="card-header">
				<span v-text="album.name"></span>
				–
				<ArtistsList :artists="album.artists" :link="true"></ArtistsList>
			</div>
			<div class="tracks list-group list-group-flush">
				<div class="list-group-item" v-for="track in album.tracks.items" :key="track.id">
					<div class="me-2 position">
						#<span v-text="track.track_number"></span>
					</div>
					<router-link class="text-decoration-none" :to="`/track/${track.id}`">
						<span v-text="track.name"></span>
					</router-link>
					<small class="artists">
						–
						<ArtistsList :artists="track.artists" :link="true"></ArtistsList>
					</small>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { useAlbumStore } from '../stores/album';
import ArtistsList from './ArtistsList.vue';

export default {
	name: 'Album',
	components: { ArtistsList },	props: {
		id: String,
	},
	data: function data() {
		return {
			albumStore: useAlbumStore(),
			album: null,
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: async function (val) {
				this.album = await this.albumStore.fetch(val);
			}
		}
	},
};
</script>

<style lang="less" scoped>
.position {
	display: inline-block;
	min-width: 3em;
}

.spotify-card-header-image {
	max-height: 1.5em;
}
</style>
