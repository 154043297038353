<template>
	<section class="artist-page">
		<div class="row">
			<div class="col-md-12">
				<Artist :id="$route.params.id"></Artist>
			</div>
			<div class="col-md-6">
				<ArtistTopTracks :id="$route.params.id"></ArtistTopTracks>
			</div>
			<div class="col-md-6">
				<ArtistAlbums :id="$route.params.id"></ArtistAlbums>
			</div>
		</div>
	</section>
</template>
<script>
import Artist from '@/components/Artist.vue';
import ArtistTopTracks from '@/components/ArtistTopTracks.vue';
import ArtistAlbums from '@/components/ArtistAlbums.vue';

export default {
	name: 'ArtistPage',
	components: {
		Artist,
		ArtistTopTracks,
		ArtistAlbums,
	},
};
</script>
