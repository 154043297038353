<template>
	<section class="track card" v-if="track">
		<div class="card-header">
			<div class="row">
				<div class="col">Track</div>
				<div class="col-auto">
					<a class="d-input-block" target="_blank" :href="track.external_urls.spotify">
						<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
					</a>
				</div>
			</div>
		</div>
		<div class="card-body">
			<dl class="row mb-0">
				<dt class="col-md-3">Name</dt>
				<dd class="col-md-9" v-text="track.name"></dd>
				<dt class="col-md-3">Album</dt>
				<dd class="col-md-9">
					<router-link class="text-decoration-none" :to="`/album/${track.album.id}`" v-text="track.album.name"></router-link>
				</dd>
				<dt class="col-md-3">Artists</dt>
				<dd class="col-md-9">
					<ArtistsList :artists="track.artists" :link="true"></ArtistsList>
				</dd>
				<dt class="col-md-3">Duration</dt>
				<dd class="col-md-9">
					<span v-text="formatTime(track.duration_ms)"></span> min
				</dd>
				<dt class="col-md-3">Popularity</dt>
				<dd class="col-md-9">
					<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Popularity: ${track.popularity}/100`">
						<div class="progress-bar" role="progressbar" :style="`width: ${track.popularity}%`" v-text="`${track.popularity}%`"></div>
					</div>
				</dd>
				<template v-if="track.preview_url">
					<dt class="col-md-3">Preview</dt>
					<dd class="col-md-9">
						<audio controls class="w-100 d-block" preload="none">
							<source :src="track.preview_url" type="audio/mpeg" />
						</audio>
					</dd>
				</template>
			</dl>
			<template v-if="trackAudioFeatures">
				<h3 class="mt-2">Audio Features</h3>
				<dl class="row mb-0">
					<dt class="col-md-3">Danceability</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Danceability: ${toFixed(trackAudioFeatures.danceability*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.danceability*100}%`" v-text="`${toFixed(trackAudioFeatures.danceability*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Energy</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Energy: ${toFixed(trackAudioFeatures.energy*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.energy*100}%`" v-text="`${toFixed(trackAudioFeatures.energy*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Speechiness</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Speechiness: ${toFixed(trackAudioFeatures.speechiness*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.speechiness*100}%`" v-text="`${toFixed(trackAudioFeatures.speechiness*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Acousticness</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Acousticness: ${toFixed(trackAudioFeatures.acousticness*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.acousticness*100}%`" v-text="`${toFixed(trackAudioFeatures.acousticness*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Instrumentalness</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Instrumentalness: ${toFixed(trackAudioFeatures.instrumentalness*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.instrumentalness*100}%`" v-text="`${toFixed(trackAudioFeatures.instrumentalness*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Liveness / Audience</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Liveness: ${toFixed(trackAudioFeatures.liveness*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.liveness*100}%`" v-text="`${toFixed(trackAudioFeatures.liveness*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Valence / Happyness</dt>
					<dd class="col-md-9">
						<div class="progress my-1" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="`Valence: ${toFixed(trackAudioFeatures.valence*100)}%`">
							<div class="progress-bar" role="progressbar" :style="`width: ${trackAudioFeatures.valence*100}%`" v-text="`${toFixed(trackAudioFeatures.valence*100)}%`"></div>
						</div>
					</dd>

					<dt class="col-md-3">Tempo</dt>
					<dd class="col-md-9" v-text="`${trackAudioFeatures.tempo} bpm`"></dd>
					<dt class="col-md-3">Loudness</dt>
					<dd class="col-md-9" v-text="`${trackAudioFeatures.loudness} db`"></dd>
					<dt class="col-md-3">Mode</dt>
					<dd class="col-md-9" v-text="`${trackAudioFeatures.mode?'major':'minor'}`"></dd>
					<dt class="col-md-3">Time Signature</dt>
					<dd class="col-md-9" v-text="`${trackAudioFeatures.time_signature}/4`"></dd>
				</dl>
			</template>
			<TrackAudioAnalysis
				v-if="trackAudioAnalysis"
				:trackAudioAnalysis="trackAudioAnalysis"
			></TrackAudioAnalysis>
		</div>
	</section>
</template>
<script>
import { useToastStore } from '../stores/toast';
import { useTrackAudioFeatureStore } from '../stores/trackaudiofeatures';
import { useTrackAudioAnalysisStore } from '../stores/trackaudioanalysis';
import { useTrackStore } from '../stores/track';
import ArtistsList from './ArtistsList.vue';
import TrackAudioAnalysis from './TrackAudioAnalysis.vue';


export default {
	name: 'Track',
	props: {
		id: String,
	},
	components: {
		ArtistsList,
		TrackAudioAnalysis,
	},
	data: function data() {
		return {
			track: null,
			toastStore: useToastStore(),
			trackAudioFeatureStore: useTrackAudioFeatureStore(),
			trackAudioAnalysisStore: useTrackAudioAnalysisStore(),
			trackStore: useTrackStore(),
			trackAudioFeatures: null,
			trackAudioAnalysis: null,
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: async function (id) {
				this.track = await this.trackStore.fetch(id);
				this.trackAudioFeatures = await this.trackAudioFeatureStore.fetch(id);
				this.trackAudioAnalysis = await this.trackAudioAnalysisStore.fetch(id);
			},
		},
	},
	methods: {
		formatTime: function(ms) {
			const min = Math.floor(ms / 60000);
			const sec = Math.floor(ms / 1000) % 60;
			return `${min}:${sec<10?'0'+sec:sec}`;
		},
		toFixed: function(val) {
			return val.toFixed(1);
		},
	},
};
</script>

<style lang="less" scoped>
.spotify-card-header-image {
	max-height: 1.5em;
}

</style>
