<template>
	<section class="playlist card" v-if="playlist">
		<div class="card-header">
			<div class="row">
				<div class="col">Playlist</div>
				<div class="col-auto">
					<a class="d-input-block" target="_blank" :href="playlist.external_urls.spotify">
						<img class="spotify-card-header-image" src="../assets/Spotify_Logo_RGB_Green.png" />
					</a>
				</div>
			</div>
		</div>
		<div class="list-group list-group-flush">
			<div class="list-group-item" v-for="(track) in tracks" v-bind:key="track.track.id">
				<router-link class="text-decoration-none" :to="`/track/${track.track.id}`" v-text="track.track.name"></router-link>
				–
				<ArtistsList :artists="track.track.artists" :link="true"></ArtistsList>
			</div>
		</div>
	</section>
</template>
<script>
import { useToastStore } from '../stores/toast';
import ArtistsList from './ArtistsList.vue';

export default {
	name: 'Playlist',
	props: {
		id: String,
	},
	components: {
		ArtistsList,
	},
	data: function data() {
		return {
			playlist: null,
			tracks: [],
			toastStore: useToastStore(),
		};
	},
	watch: {
		id: {
			immediate: true,
			handler: function () {
				this.fetchPlaylist();
			}
		}
	},
	methods: {
		fetchPlaylist: async function() {
			this.tracks = [];
			let response;
			let offset = 0;
			try {
				do {
					if (offset === 0) {
						response = await this.spotifyApi.getPlaylist(this.id);
						this.playlist = response.body;
						this.tracks.push(...response.body.tracks.items);
					} else {
						response = await this.spotifyApi.getPlaylistTracks(this.id, {
							offset,
						});
						this.tracks.push(...response.body.items);
					}
					offset += 100;
				} while (response && response.body && (
					response.body.tracks && response.body.tracks.items.length === 100 ||
					response.body.items && response.body.items.length === 100
				))
			} catch (e) {
				this.toastStore.addToast({
					header: 'Fetch playlist error',
					text: e,
					type: 'danger',
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.spotify-card-header-image {
	max-height: 1.5em;
}

</style>
