<template>
	<section class="legal-page">
		<h3>Legal</h3>

		<div class="card mb-4">
			<div class="card-header">Operator</div>
			<div class="card-body">
				<blockquote class="mb-0">
					Martin Freisen<br/>
					Kuckucksweg 27<br/>
					33607 Bielefeld<br/>
					martin.freisen@gmail.com
				</blockquote>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-header">GDPR</div>
			<div class="card-body">
				<h5>Stored data</h5>
				<p>The Spotify accountdata that will be stored with this service consists of your spotify ID, the user display name, access token, refresh token and product type</p>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-header">Cookies</div>
			<div class="card-body">
				<p>
					<strong>connect.sid</strong>:
					The session id that is required to keep a user logged in.
				</p>
				<p>
					All other cookies are used by the Spotify Web Player which will be used if you login using a Spotify account. The Spotify Cookie Policy can be found <a href="https://www.spotify.com/us/legal/cookies-policy/">here</a>.
				</p>
				<p>
					The Spotify Web Player also uses Google Analytics. Privacy and usage information for Google Analytics can be found <a href="https://www.google.com/policies/privacy/partners">here</a>.
				</p>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-header">Spotify</div>
			<div class="card-body">
				<p>When using a Spotify account to log in, you'll be shown a list of scopes that are needed for this page to work. More information about scopes can be found <a href="https://developer.spotify.com/documentation/general/guides/authorization/scopes">here</a></p>
				<ul class="list-group">
					<li class="list-group-item"><strong>playlist-modify-private</strong>: Needed to modify playlists that have been set to private.</li>
					<li class="list-group-item"><strong>playlist-modify-public</strong>: Needed to modify playlists that have been set to public.</li>
					<li class="list-group-item"><strong>playlist-read-collaborative</strong>: Needed to read playlists that have been set to collaborative.</li>
					<li class="list-group-item"><strong>playlist-read-private</strong>: Needed to read playlists that have been set to private.</li>
					<li class="list-group-item"><strong>streaming</strong>: Needed by the Web Player to play songs in the browser.</li>
					<li class="list-group-item"><strong>user-modify-playback-state</strong>: Needed to play/pause/skip songs in the browser and transfer playback between devices.</li>
					<li class="list-group-item"><strong>user-read-currently-playing</strong>: Needed to check which song is currently playing.</li>
					<li class="list-group-item"><strong>user-read-playback-position</strong>: Needed to check the playback position and show a progress bar.</li>
					<li class="list-group-item"><strong>user-read-playback-state</strong>: Needed to check which song is currently playing and which device is used.</li>
					<li class="list-group-item"><strong>user-read-private</strong>: Needed to check whether the Spotify account is free or premium.</li>
				</ul>
			</div>
		</div>

	</section>
</template>
<script>

export default {
	name: 'LegalPage',
	components: {
	},
};
</script>

<style lang="less" scoped>
blockquote {
	white-space: pre-line;
}
</style>
