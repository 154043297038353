<template>
	<section class="party-songs">
		<div class="card mb-4">
			<div class="card-header">
				<div class="row">
					<div class="col">Songs</div>
					<div class="col-auto">
						<div class="form-check">
							<input id="isHidePlayedSongs" type="checkbox" class="form-check-input" v-model="isHidePlayedSongs" />
							<label for="isHidePlayedSongs" class="form-check-label">Hide played songs</label>
						</div>
					</div>
				</div>
			</div>
			<table v-if="songs && songs.length > 0" class="table table-striped table-hover table-borderless mb-0">
				<thead class="table-secondary">
					<tr>
						<th class="text-end">#</th>
						<th>Track</th>
						<th class="text-end">Votes</th>
					</tr>
				</thead>
				<transition-group name="flip-list" tag="tbody">
					<template
						v-for="song in filteredSongs"
					>
						<PartySong
							:key="song.id"
							:song="song"
							:party="partyStore.party"
							:playState="playState"
							:trackPlaying="trackPlaying"
						></PartySong>
					</template>
				</transition-group>
			</table>
			<div v-else class="card-body">
				Playlist is empty.
			</div>
		</div>
	</section>
</template>

<script>
import { usePlayStateStore } from '../stores/playstate';
import { usePartyStore } from '../stores/party';
import PartySong from './PartySong.vue';


export default {
	name: 'PartySongs',
	props: {
		party: Object,
	},
	components: {
		PartySong,
	},
	data: function data() {
		return {
			songs: [],
			isHidePlayedSongs: true,
			playStateStore: usePlayStateStore(),
			partyStore: usePartyStore(),
		};
	},
	created: async function() {
		this.fetchSongs();
	},
	computed: {
		filteredSongs: function() {
			if (!this.playState || !this.isHidePlayedSongs) {
				return this.songs;
			}
			const songIdx = this.songs.findIndex((song) => {
				return this.trackPlaying && this.trackPlaying.id === song.spotify_id;
			});
			console.log('idx', songIdx);
			if (songIdx === -1) {
				return this.songs;
			}
			return this.songs.slice(songIdx);
		},
		playState: function () {
			return this.playStateStore.playState;
		},
		trackPlaying: function () {
			return this.playState && this.playState.track_window.current_track;
		},
	},
	methods: {
		fetchSongs: async function() {
			this.songs = await this.partyStore.fetchSongs(this.party);
		},
	}
};
</script>

<style lang="less" scoped>
.flip-list-move {
	transition: transform 1s;
}
</style>
