import {
	defineStore
} from "pinia";

export const useSearchStore = defineStore('search', {
	state: () => ({
		searchQuery: null,
		searchResults: {},
	}),
	getters: {
		getResult: (state) => {
			return (query) => state.searchResults[query] || [];
		},
	},
	actions: {
		addResults (query, results) {
			this.searchResults[query] = results;
		},
	},
});
