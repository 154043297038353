<template>
	<section class="spotify-player">
		<script src="https://sdk.scdn.co/spotify-player.js" type="application/javascript" async></script>
	</section>
</template>

<script>
import { useValueStore } from '../stores/values';
import { useUserStore } from '../stores/user';
import { usePlayStateStore } from '../stores/playstate';

export default {
	name: 'SpotifyPlayer',
	data: function() {
		return {
			player: null,
			userStore: useUserStore(),
			valueStore: useValueStore(),
			playStateStore: usePlayStateStore(),
			isSdkReady: false,
			isInitialized: false,
		};
	},
	beforeMount: function() {
		window.onSpotifyWebPlaybackSDKReady = () => this.isSdkReady = true;
	},
	beforeDestroy: function(){
		if (this.player) {
			this.player.disconnect();
		}
	},
	computed: {
		user: function () {
			return this.userStore.user;
		},
	},
	watch: {
		user: function () {
			console.log('user changed, initializing web player');
			this.sdkReady();
		},
		isSdkReady: function () {
			console.log('sdk ready changed, initializing web player');
			this.sdkReady();
		},
	},
	methods: {
		sdkReady: function() {
			if (!this.isSdkReady || !this.user || this.isInitialized) {
				console.log('condition to initialize player was not met. aborting.');
				return;
			}
			this.player = new Spotify.Player({
				name: 'playlist.one',
				getOAuthToken: cb => {
					console.log('web player getting oauth token');
					cb(this.userStore.user.access_token);
				},
				volume: 0.7
			});
			window.player = this.player;
			this.player.addListener('ready', ({ device_id }) => {
				console.log('web player ready', device_id);
				this.valueStore.localDeviceID = device_id;
			});

			this.player.addListener('not_ready', ({ device_id }) => {
				console.log('web player not ready', device_id);
				this.valueStore.localDeviceID = null;
			});

			this.player.addListener('player_state_changed', (state) => {
				console.log('web player state changed', state);
				this.playStateStore.setPlayState(state, {
					post: this.valueStore.isActiveDevice,
				});
			});

			this.player.connect();
			this.isInitialized = true;
		}
	},
};
</script>
