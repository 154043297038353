import {
	defineStore
} from "pinia";

export const useToastStore = defineStore('toast', {
	state: () => ({
		toasts: [],
	}),
	getters: {
	},
	actions: {
		addToast(opts) {
			this.toasts.push({
				header: opts.header,
				text: opts.text,
				duration: opts.duration || 5000,
				type: opts.type,
				addedMS: Date.now(),
			});
		},
		removeToast(idx) {
			this.toasts.splice(idx, 1);
		},
	},
});
