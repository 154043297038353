<template>
	<nav id="nav" class="navbar navbar-expand-md navbar-dark bg-secondary mb-4 py-2 border-bottom d-print-none">
		<div class="container">
			<router-link to="/" class="navbar-brand" exactActiveClass="active">
				<img src="../assets/favicon.svg" height="26" width="26" />
				playlist.one
			</router-link>
			<button v-if="userStore.user" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div v-if="userStore.user" class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-md-0">
					<li class="nav-item"></li>
				</ul>
				<ul class="navbar-nav mb-2 mb-md-0">
					<li class="nav-item dropdown" v-if="userStore.user.id">
						<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" v-text="userStore.user.display_name"></a>
						<ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
							<router-link to="/settings" class="dropdown-item">Settings</router-link>
							<li><a href="/logout" class="dropdown-item">Logout</a></li>
						</ul>
					</li>
					<li v-if="userStore.user.voter_id" class="nav-item"><a href="/logout" class="btn btn-primary ms-4">Logout</a></li>
				</ul>
			</div>
		</div>
	</nav>

</template>
<script>
import { useUserStore } from '../stores/user';
export default {
	name: 'Nav',
	props: {},
	data: function() {
		return {
			userStore: useUserStore(),
		};
	},
	computed: {
		isPartyPage: function() {
			return this.$route.name === 'PartyPage';
		}
	},
};
</script>

<style lang="less" scoped>
#nav {
	a {
		&.router-link-exact-active {
			color: var(--bs-primary);
		}
	}
}
</style>
