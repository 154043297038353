<template>
	<section class="home-page">
		<template v-if="userStore.user.id">
			<div class="row">
				<div class="col-lg-6">
					<!-- <Playlists /> -->
					<Parties />
				</div>
				<div class="col-lg-6">
					<PartyForm />
				</div>
			</div>
		</template>
		<template v-if="userStore.user.party_id">
			<router-link :to="`/party/${userStore.user.party_id}`">Show party</router-link>
		</template>
	</section>
</template>

<script>
// @ is an alias to /src
import Parties from '../components/Parties.vue';
import PartyForm from '../components/PartyForm.vue';
import { useUserStore } from '../stores/user';


export default {
	name: 'HomePage',
	components: {
		Parties,
		PartyForm,
		// Playlists,
	},
	data: function () {
		return {
			userStore: useUserStore(),
		}
	},
	mounted: function() {
		if (this.userStore.user.party_id) {
			this.$router.push(`/party/${this.userStore.user.party_id}`);
		}
	},
};
</script>
