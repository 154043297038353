import {
	defineStore
} from "pinia";
import { handleStatusError } from "@/lib/fetchHandlers";

export const useUserStore = defineStore('user', {
	state: () => ({
		user: false,
	}),
	getters: {
	},
	actions: {
		fetchUser() {
			fetch('/api/user/me')
				.then(handleStatusError)
				.then((data) => {
					this.user = data.user;
				})
				.catch((err) => {
					this.user = null;
					console.error(err, err.response);
				});
		},
	},
});
