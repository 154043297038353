<template>
	<section class="settings-page">
		<div class="card">
			<div class="card-header">Settings</div>
			<div class="card-body">
				<div class="row" v-if="userStore.user.id">
					<label class="col-form-label col-sm-4 text-sm-end">Account deletion</label>
					<div class="col-sm-8">
						<button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteAccount">Delete account</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="deleteAccount" aria-hidden="true" >
			<div class="modal-dialog">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Delete account</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					Are you sure you want to delete your account?<br />
					All your parties and votes will be irreversibly gone.<br />
					Your Spotify data will not be touched.
				</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" data-bs-dismiss="modal"  @click="deleteAccount">Delete account</button>
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import { handleStatusError, handleErrorToast } from '@/lib/fetchHandlers.js';
import { useUserStore } from '../stores/user';

export default {
	name: 'SettingsPage',
	data: function () {
		return {
			userStore: useUserStore(),
		}
	},
	methods: {
		deleteAccount: function(song) {
			fetch(`/api/user/me`, {
				method: 'delete',
				headers: {
					'Content-Type': 'application/json'
				},
			})
				.then(handleStatusError)
				.then((response) => {
					console.log('account deleted', response);
					this.userStore.user = null;
					this.$router.push('/');
				})
				.catch(handleErrorToast(err, 'Delete user error'));
		},
	}
};
</script>
