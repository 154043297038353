import {
	defineStore
} from "pinia";
import { handleErrorToast } from '../lib/fetchHandlers'

export const useArtistStore = defineStore('artist', {
	state: () => ({
		artists: {},
	}),
	getters: {
		get: (state) => {
			return (id) => state.artists[id] || null;
		},
	},
	actions: {
		add (id, results) {
			this.artists[id] = results;
		},
		async fetch (id) {
			if (this.artists[id]) {
				return this.artists[id];
			}
			return window.spotifyApi.getArtist(id)
				.then((data) => {
					this.add(id, data.body);
					return data.body;
				}, (err) => {
					handleErrorToast(err, 'Fetch artist error');
					return null;
				});
		},
	},
});
