<template>
	<section class="party-code-page">
		<PartyCodes :id="$route.params.id"></PartyCodes>
	</section>
</template>
<script>
import PartyCodes from '../components/PartyCodes.vue';

export default {
	name: 'PartyCodePage',
	components: {
		PartyCodes,
	},
};
</script>
