<template>
	<section class="party-page">
		<Party :id="$route.params.id"></Party>
	</section>
</template>
<script>
import Party from '../components/Party.vue';

export default {
	name: 'PartyPage',
	components: {
		Party,
	},
};
</script>
