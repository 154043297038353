import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue';
import PlaylistPage from '../views/PlaylistPage.vue';
import ArtistPage from '../views/ArtistPage.vue';
import AlbumPage from '../views/AlbumPage.vue';
import TrackPage from '../views/TrackPage.vue';
import PartyPage from '../views/PartyPage.vue';
import PartyEditPage from '../views/PartyEditPage.vue';
import PartyCodePage from '../views/PartyCodePage.vue';
import SettingsPage from '../views/SettingsPage.vue';
import LegalPage from '../views/LegalPage.vue';

Vue.use(VueRouter);

const routes = [{
	path: '/',
	name: 'HomePage',
	component: HomePage,
}, {
	path: '/login',
	name: 'LoginPage',
	component: LoginPage,
}, {
	path: '/playlist/:id',
	name: 'PlaylistPage',
	component: PlaylistPage,
}, {
	path: '/artist/:id',
	name: 'ArtistPage',
	component: ArtistPage,
}, {
	path: '/album/:id',
	name: 'AlbumPage',
	component: AlbumPage,
}, {
	path: '/track/:id',
	name: 'TrackPage',
	component: TrackPage,
}, {
	path: '/party/:id',
	name: 'PartyPage',
	component: PartyPage,
}, {
	path: '/party/:id/edit',
	name: 'PartyEditPage',
	component: PartyEditPage,
}, {
	path: '/party/:id/codes',
	name: 'PartyCodePage',
	component: PartyCodePage,
}, {
	path: '/settings',
	name: 'SettingsPage',
	component: SettingsPage,
}, {
	path: '/legal',
	name: 'LegalPage',
	component: LegalPage,
}];

const router = new VueRouter({
	mode: 'hash',
	//   base: process.env.BASE_URL,
	routes,
	behavior: 'auto',
	scrollBehavior(to, from, savedPosition) {
		if (!savedPosition) {
			return {
				x: 0,
				y: 0,
			};
		}
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				return resolve(savedPosition);
			}, 400);
		});
	}
});

export default router;
